//ActionStoryButton.jsx
import React from 'react';
import './ActionStoryButton.css';
import { Link } from 'react-router-dom';

function ActionStoryButton(props) {
    return (
        <Link to={props.link} className='action-story-button'>
            {props.children}
        </Link>
    );
}

export default ActionStoryButton;

