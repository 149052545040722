import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./DashBoard.css";
import "../../App.css";
import Stats from "./Stats/Stats";
/* import Achivments from "./Achivments/Achivments"; */

import { trainingAndThemeModeLinkSwitch } from "../../shared/functions/trainingAndThemeModeLinkSwitch";

const DashBoard = (props) => {
  /* const achivmentsLS = localStorage.getItem("achivments"); */
  let ticketStatusArray = JSON.parse(localStorage.getItem("ticketStatusArray"));
  let errorsArrayAB;
  let errorsArrayCD;
  const [errorsArray, setErrorsArray] = useState(
    JSON.parse(localStorage.getItem("errorsArray"))
  );

  useEffect(() => {
    if (errorsArray) {
      const check = errorsArray.filter((el) => el.ticketErrors.length !== 0);
      if (check.length === 0) {
        localStorage.removeItem("errorsArray");
        setErrorsArray(null);
      }
    }
  }, [errorsArray]);

  const countAllErrors = (arr) => {
    if (arr && arr.length !== 0) {
      let errCounter = 0;
      arr.forEach((element) => (errCounter += element.ticketErrors.length));
      return errCounter;
    }
  };

  const errorsLinkSwitch = (errorsArray) => {
    if (errorsArray && errorsArray.length !== 0) {
      errorsArrayAB = errorsArray.filter(
        (ticket) => ticket.ticketCategory === "ticketsAB"
      );
      errorsArrayCD = errorsArray.filter(
        (ticket) => ticket.ticketCategory === "ticketsCD"
      );
      if (errorsArrayAB.length !== 0) {
        const minTicketAB = errorsArrayAB.reduce((prev, curr) =>
          prev.ticketId < curr.ticketId ? prev : curr
        ).ticketId;
        const minTicketABIndex = errorsArrayAB.findIndex(
          (item) => item.ticketId === minTicketAB
        );
        const minTaskAB = Math.min.apply(
          Math,
          errorsArrayAB[minTicketABIndex].ticketErrors
        );
        return `/errorsAB/${minTicketAB}/${minTaskAB}/`;
      } else if (errorsArrayCD.length !== 0) {
        const minTicketCD = errorsArrayCD.reduce((prev, curr) =>
          prev.ticketId < curr.ticketId ? prev : curr
        ).ticketId;
        const minTicketCDIndex = errorsArrayCD.findIndex(
          (item) => item.ticketId === minTicketCD
        );
        const minTaskCD = Math.min.apply(
          Math,
          errorsArrayCD[minTicketCDIndex].ticketErrors
        );
        return `/errorsCD/${minTicketCD}/${minTaskCD}/`;
      }
    } else return "/errorsAB/1/1/";
  };

  return (
    <>
      <div className="dash shared-card">
        <h1 id="dash-main-header" className="dash-header"> Обучение ПДД</h1>
        <div className="mode-grid">
          <Link
            className="mode-selector"
            to={`/examAB/1/`}
            onClick={window.scrollTo(0, 0)}
          >
            <h2 className="mode-selector-header">Экзамен</h2>
            <p className="mode-selector-description">
              Создаём уникальные билеты. В случае ошибки добавим +5 минут и +5
              вопросов. Категории AB или CD
            </p>
          </Link>

          <Link
            className="mode-selector"
            to={trainingAndThemeModeLinkSwitch(ticketStatusArray, "ticketsAB")}
            onClick={() => window.scrollTo(0, 0)}
          >
            <h2 className="mode-selector-header">Билеты ПДД</h2>
            <p className="mode-selector-description">
              40 билетов по 20 вопросов. На каждый билет 20 минут. Не более 2
              ошибок. Категории AB или CD
            </p>
          </Link>

          <Link
            className="mode-selector"
            to={errorsLinkSwitch(errorsArray)}
            onClick={window.scrollTo(0, 0)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="mode-selector-header" style={{ marginBottom: "0" }}>Ошибки</h2>
              {errorsArray && (
                <p style={{ marginBottom: "0", marginTop: "22px" }} className="error-badge">{countAllErrors(errorsArray)}</p>
              )}
            </div>

            <p>Вопросы, в которых вы допустили ошибки</p>
          </Link>

          <Link
            className="mode-selector"
            to={"/rules/1/"}
            onClick={window.scrollTo(0, 0)}
          >
            <h2 className="mode-selector-header">Правила и знаки</h2>
            <p className="mode-selector-description">Учите правила дорожного движения и дорожные знаки. От 1 сентября 2023</p>
          </Link>


          <Link
            className="mode-selector"
            to={"/videos/"}
            onClick={window.scrollTo(0, 0)}
          >
            {/* <div style={{ display: "flex", alignItems: "center" }}> */}
            <h2 className="mode-selector-header">Видео {/* <span className="success-badge">Новое</span> */}</h2>
            
            <p className="mode-selector-description">Подборка видео на автомобильные темы: Автошкола, Разбор ДТП, Устройство автомобилей, Автошоу, Автоблоггеры, Автоюристы</p>
          </Link>

          <Link
            className="mode-selector"
            to={"/articles/other/"}
            onClick={window.scrollTo(0, 0)}
          >
            {/* <div style={{ display: "flex", alignItems: "center" }}> */}
            <h2 className="mode-selector-header">Статьи  {/* <span className="success-badge">Новое</span> */}</h2>
            
            <p className="mode-selector-description">Подборка интересных статей на авто тему</p>
          </Link>

          <Link
            className="mode-selector mode-selector-extended"
            to={trainingAndThemeModeLinkSwitch(ticketStatusArray, "themeAB")}
            onClick={window.scrollTo(0, 0)}
          >
            <h2 className="mode-selector-header">Вопросы по темам</h2>
            <p>800 вопросов разбиты на темы. Без ограничения по времени. Допускается совершить не более 10% ошибок на тему. Категория AB</p>
          </Link>
          <Link
            className="mode-selector mode-selector-extended"
            to="/newAB/1/"
            onClick={window.scrollTo(0, 0)}
          >
            <h2 className="mode-selector-header">Изменения с 1 сентября 2023 и 1 марта 2023</h2>
            <p>Подборка вопросов, которые были изменены с 1 сентября 2023 и 1 марта 2023</p>
          </Link>
        </div>
      </div>
      <div className="dash shared-card">
        <Stats setErrorsArray={setErrorsArray} />
      </div>
      {/*  {achivmentsLS && (
        <div className="dash shared-card">
          <Achivments />
        </div>
      )} */}
    </>
  );
};

export default DashBoard;

