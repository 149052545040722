// highlightsActions.js
import axios from "axios";
import {
    GET_HIGHLIGHTS_REQUEST,
    GET_HIGHLIGHTS_SUCCESS,
    GET_HIGHLIGHTS_FAIL,
    SET_ANSWERED_INDEX,
    GET_TASKS_STATS_REQUEST,
    GET_TASKS_STATS_SUCCESS,
    GET_TASKS_STATS_FAIL,
} from "../constants/highlightsConstants";

export const getHighlights = () => async (dispatch) => {
    try {
        dispatch({ type: GET_HIGHLIGHTS_REQUEST });

        const { data } = await axios.get("/api/stories/");

        // Check for existing data in localStorage
        const existingData = JSON.parse(localStorage.getItem("highlightsData"));

        if (existingData) {
            // Compare and update seen status if necessary
            const updatedData = { ...data };
            Object.keys(data).forEach(category => {
                updatedData[category] = data[category].map(story => {
                    const existingStory = existingData[category]?.find(s => s.storyId === story.storyId);
                    if (category === "tasksAB" || category === "tasksCD") {
                        return {
                            ...story,
                            answeredIndex: existingStory ? existingStory.answeredIndex : false,
                            seen: existingStory ? existingStory.seen : false
                        };
                    } else
                        return {
                            ...story,
                            seen: existingStory ? existingStory.seen : false
                        };
                });
            });

            // Update localStorage with the merged data
            localStorage.setItem("highlightsData", JSON.stringify(updatedData));
        } else {
            // If no existing data, initialize seen status and save to localStorage
            const initializedData = { ...data };

            Object.keys(data).forEach(category => {
                initializedData[category] = data[category].map(story => {
                    if (category === "tasksAB" || category === "tasksCD") {
                        return {
                            ...story,
                            answeredIndex: false,
                            seen: false // Assume stories are not answered at the time of fetching
                        }
                    } else
                        return {
                            ...story,
                            seen: false // Initialize seen status to false
                        }
                });
            });

            localStorage.setItem("highlightsData", JSON.stringify(initializedData));
        }

        dispatch({
            type: GET_HIGHLIGHTS_SUCCESS,
            payload: JSON.parse(localStorage.getItem("highlightsData")) // Use the data from localStorage
        });
    } catch (error) {
        dispatch({
            type: GET_HIGHLIGHTS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
}

export const setAnsweredIndex = (storyId, answeredIndex) => async (dispatch) => {
    const highlightsData = JSON.parse(localStorage.getItem("highlightsData"));

    Object.keys(highlightsData).forEach(category => {
        const storyIndex = highlightsData[category].findIndex(s => s.storyId === storyId);
        if (storyIndex !== -1) {
            highlightsData[category][storyIndex].answeredIndex = answeredIndex; // Mark the story as answered
        }
    });

    localStorage.setItem("highlightsData", JSON.stringify(highlightsData));

    dispatch({
        type: SET_ANSWERED_INDEX,
        payload: { storyId, answeredIndex }
    });
}

export const sendTaskResults = (results) => async (dispatch) => {
    try {
        await axios.post("/api/stats/dailyTask/", results);
    } catch (error) {
        console.log(error);
    }
}

export const getTasksStats = (body) => async (dispatch) => {
    try {
        dispatch({ type: GET_TASKS_STATS_REQUEST });

        const { data } = await axios.post("/api/stats/dailyTasks/", body);

        dispatch({
            type: GET_TASKS_STATS_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: GET_TASKS_STATS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        });
    }
}

