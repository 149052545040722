import React from 'react';
import TicketBody from './TicketBody';

function TicketH({ ticket }) {
    return (
        <div>
            <TicketBody
                key={ticket.taskId}
                img={ticket.img}
                question={ticket.question}
                answers={ticket.answers}
                rightAnswer={ticket.rightAnswer}
                taskId={ticket.taskId}
                ticketId={ticket.ticketId}
                category={ticket.category}
                answeredIndex={ticket.answeredIndex}
                storyId={ticket.storyId}
            />
        </div>
    );
}

export default TicketH;