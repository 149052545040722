import React from "react";

function Confirm(props) {
  return (
    <div style={{marginTop:"2.5rem"}}>
      <p style={{textAlign: "center", margin: "0 0 0.5rem 0", fontWeight:"bold"}}>{props.children}</p>
      <div className="controll-buttons">
        <div className="button" onClick={()=>{props.action(true) }}>
          Да
        </div>
        <div className="button"  onClick={()=>{props.action(false) }}>
          Нет
        </div>
      </div>
    </div>
  );
}

export default Confirm;
