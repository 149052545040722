//TicketBody.jsx
import React, { useState, useEffect } from "react";

import "./TicketBody.css";
import Answer from "./Answer";
import ImageWrapper from "../../shared/components/ImageWrapper";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setAnsweredIndex, sendTaskResults, getTasksStats } from "../../store/actions/highlightsActions";

const TicketBody = ({ img, question, answers, rightAnswer, taskId, ticketId, category, answeredIndex, storyId }) => {
  const dispatch = useDispatch();
  const { loading, error, tasksStats } = useSelector((state) => state.highlightsTasksReducer);
  const [isError, setIsError] = useState(null);
  const [clickedAnswerId, setClickedAnswerId] = useState(null);
  const [taskStat, setTaskStat] = useState(null);

  const wrongAnswerHandler = async (clickedAnswerId, isError) => {
    if (isError) {
      setIsError(isError);
    }
    setClickedAnswerId(clickedAnswerId);
    dispatch(setAnsweredIndex(storyId, clickedAnswerId));
    dispatch(sendTaskResults({ category, ticketId, taskId, answer: clickedAnswerId }));
  }

  useEffect(() => {
    if (answeredIndex !== false) {
      dispatch(getTasksStats({ arr: [{ category, ticketId, taskId }] }));
    }
  }, [answeredIndex, category, ticketId, taskId, dispatch]);

  useEffect(() => {
    if (tasksStats) {
      const taskStat = tasksStats.find((task) => task.taskId === taskId && task.ticketId === ticketId && task.category === category);
      setTaskStat(taskStat);
    }
  }, [tasksStats, taskId, ticketId, category]);

  return (
    <div key={taskId} className="ticket-h">
      {img &&
        <ImageWrapper src={img} alt="Картинка к вопросу" draggable="false" />
      }
      <h2 itemProp="name" className="question">
        {question}
      </h2>
      {loading && <div>Загрузка статистики ответов...</div>}
      {error && <div>{error}</div>}
      <div className="answers">
        {answers.map((answer, index) => (
          <Answer
            key={index}
            id={index}
            answer={answer}
            rightAnswer={rightAnswer}
            isError={isError}
            clickedAnswerId={clickedAnswerId}
            wrongAnswerHandler={wrongAnswerHandler}
            answeredIndex={answeredIndex}
            storyId={storyId}
            taskStat={tasksStats && taskStat}
            summary={taskStat && Object.values(taskStat.stats).reduce((a, b) => a + b, 0)}
          />
        ))}
      </div>
    </div>
  );
};

export default TicketBody

