export const trainingAndThemeModeLinkSwitch = (ticketStatusArray, categoryTickets) => {

  if (ticketStatusArray === null) return `/${categoryTickets}/${1}/${1}/`;

  if (ticketStatusArray !== null) {
    ticketStatusArray = ticketStatusArray.filter(
      (el) => el.ticketCategory === categoryTickets
    );
  }

  let ticketsQuantity;
  let flag = true;
  let ticketLink = 1;

  if (categoryTickets === "ticketsAB" || categoryTickets === "ticketsCD") {
    ticketsQuantity = 40;
  } else if (categoryTickets === "themeAB" || categoryTickets === "themeCD") {
    ticketsQuantity = 28;
  }

  const notFound = (arr) => {
    if (arr.findIndex((element) => element.ticketId === ticketLink) === -1)
      return true;
    else return false;
  };

  while (flag) {
    if (notFound(ticketStatusArray)) {
      return `/${categoryTickets}/${ticketLink}/${1}/`;
    }
    ticketLink = ticketLink + 1;
    if (ticketLink === ticketsQuantity) flag = false;
  }

  ticketStatusArray = ticketStatusArray.filter(
    (el) => el.ticketStatus === false
  );

  if (ticketStatusArray.length !== 0) {
    ticketStatusArray.sort(function (a, b) {
      return a.ticketId - b.ticketId;
    });
    return `/${categoryTickets}/${ticketStatusArray[0].ticketId}/${1}/`;
  } else return `/${categoryTickets}/${1}/${1}/`;
};