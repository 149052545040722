import React from 'react';
import './MainStoriesComponent.css';

const MainStoriesComponentSkeleton = () => {

    const HIGHLIGHTS = [
        {
            name: 'articles',
            header: 'Статьи',
            img: null
        },
        {
            name: 'ticketsAB',
            header: 'Вопрос дня AB',
            img: null
        },
        {
            name: 'ticketsCD',
            header: 'Вопрос дня CD',
            img: null
        },
        {
            name: 'videos',
            header: 'Видео',
            img: null
        }
    ];

    return (
        <div className='main-stories-component'>
            <div className='highlights-container'>
                {HIGHLIGHTS.map((h) => (
                    <div key={h.name} className='highlight-skeleton'>
                        <div className='skeleton'>
                          
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default MainStoriesComponentSkeleton;
