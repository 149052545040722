// MainStoriesComponent.js
import React, { useState, useEffect, useCallback } from 'react';
import StoryViewer from './StoryViewer';
import Highlight from './highlights/Highlight';
import MainStoriesComponentSkeleton from './MainStoriesComponentSkeleton';

import { useDispatch, useSelector } from 'react-redux';
import { getHighlights } from '../store/actions/highlightsActions';

import './MainStoriesComponent.css';

const MainStoriesComponent = () => {
  const dispatch = useDispatch();
  const { highlights: highlightsData, loading, error } = useSelector((state) => state.highlightsReducer);
  const [currentViewer, setCurrentViewer] = useState(null);
  const [highlightRect, setHighlightRect] = useState(null);
  const [seenState, setSeenState] = useState({ 'articles': false, 'tickets': false, 'videos': false });
  const [highlights, setHighlights] = useState([]);
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState(null);

  const getFirstUnseenArticleImg = useCallback((data) => {
    if (!data || data.length <= 0) {
      return '/path/to/default/image.jpg';
    }
    const unseenArticles = data.articles.filter(article => !article.seen);

    if (unseenArticles.length > 0) {
      return unseenArticles[0].image;
    } else {
      return data.articles[0].image;
    }
  }, []);

  const getFirstUnseenVideoImg = useCallback((data) => {
    if (!data || data.length <= 0) {
      return '/path/to/default/image.jpg';
    }
    const unseenVideos = data.videos.filter(video => !video.seen);

    if (unseenVideos.length > 0) {
      return unseenVideos[0].video.snippet.thumbnails.high.url;
    } else {
      return data.videos[0].video.snippet.thumbnails.high.url;
    }
  }, []);

  const getFirstUnseenTicketsImg = useCallback((category, data) => {
    const isImg = (img) => {
      if (img === null || img === undefined || img === '') {
        return '/images/icons/logo192.png';
      }
      return img;
    }

    if (!data || data.length <= 0) {
      return '/images/icons/logo192.png';
    }
    let unseenTickets = [];
    if (category === "ab") {
      unseenTickets = data.tasksAB.filter(ticket => !ticket.seen);
    } else if (category === "cd") {
      unseenTickets = data.tasksCD.filter(ticket => !ticket.seen);
    }

    if (unseenTickets.length > 0) {
     return isImg(unseenTickets[0].img);
    }

    return category === "ab" ? isImg(data.tasksAB[0].img) : isImg(data.tasksCD[0].img);
  }, []);

  const getIndexOfFirstUnseen = (data) => {
    if (!data || data.length <= 0) {
      return 0;
    }
    if (data.findIndex(story => !story.seen) === -1) {
      return 0;
    }
    return data.findIndex(story => !story.seen);
  };


  useEffect(() => {
    dispatch(getHighlights()); // This fetches highlights data and updates local storage and Redux state
  }, [dispatch]);

  useEffect(() => {
    const updatedHighlightsData = JSON.parse(localStorage.getItem("highlightsData")) || highlightsData;
    if (!updatedHighlightsData) {
      return;
    }

    const updatedSeenState = {
      articles: checkIfAllSeen(updatedHighlightsData.articles || []),
      ticketsAB: checkIfAllSeen(updatedHighlightsData.tasksAB || []),
      ticketsCD: checkIfAllSeen(updatedHighlightsData.tasksCD || []),
      videos: checkIfAllSeen(updatedHighlightsData.videos || []),
    }

    setSeenState(updatedSeenState);

    let updatedHighlights = [
      {
        name: 'articles',
        header: 'Статьи',
        img: getFirstUnseenArticleImg(updatedHighlightsData),
        startIndex: getIndexOfFirstUnseen(updatedHighlightsData.articles),
      },
      {
        name: 'ticketsAB',
        header: 'Вопросы AB',
        img: getFirstUnseenTicketsImg("ab", updatedHighlightsData),
        startIndex: getIndexOfFirstUnseen(updatedHighlightsData.tasksAB),
      },
      {
        name: 'ticketsCD',
        header: 'Вопросы CD',
        img: getFirstUnseenTicketsImg("cd", updatedHighlightsData),
        startIndex: getIndexOfFirstUnseen(updatedHighlightsData.tasksCD),
      },
      {
        name: 'videos',
        header: 'Видео',
        img: getFirstUnseenVideoImg(updatedHighlightsData),
        startIndex: getIndexOfFirstUnseen(updatedHighlightsData.videos),
      }
    ]

    setHighlights(updatedHighlights);

  }, [highlightsData, getFirstUnseenArticleImg, getFirstUnseenTicketsImg, getFirstUnseenVideoImg]);


  const checkIfAllSeen = (stories) => {
    if (!stories) {
      return false; // Safely handle null or undefined
    }
    return stories.every(story => story.seen);
  };

  // Function to open a specific StoryViewer
  const openViewer = (viewerName, rect, highlightIndex) => {
    setCurrentViewer(viewerName);
    setHighlightRect(rect);
    setCurrentHighlightIndex(highlightIndex);
  };

  const navigateToNextHighlight = () => {
    if (currentHighlightIndex !== null && currentHighlightIndex < highlights.length - 1) {
      const nextHighlightIndex = currentHighlightIndex + 1;
      const nextHighlight = highlights[nextHighlightIndex];
      openViewer(nextHighlight.name, highlightRect, nextHighlightIndex);
    } else {
      closeViewer(); // Close the viewer if it's the last highlight
    }
  };


  const navigateToPreviousHighlight = () => {
    if (currentHighlightIndex !== null && currentHighlightIndex > 0) {
      const previousHighlightIndex = currentHighlightIndex - 1;
      const previousHighlight = highlights[previousHighlightIndex];
      openViewer(previousHighlight.name, highlightRect, previousHighlightIndex);
    } else {
      closeViewer(); // Close the viewer if it's the first highlight
    }
  };


  const closeViewer = () => {
    const updatedHighlightsData = JSON.parse(localStorage.getItem("highlightsData")) || highlightsData;

    const updatedSeenState = {
      articles: checkIfAllSeen(updatedHighlightsData.articles || []),
      ticketsAB: checkIfAllSeen(updatedHighlightsData.tasksAB || []),
      ticketsCD: checkIfAllSeen(updatedHighlightsData.tasksCD || []),
      videos: checkIfAllSeen(updatedHighlightsData.videos || []),
    }

    setSeenState(updatedSeenState);

    setHighlights([
      {
        name: 'articles',
        header: 'Статьи',
        img: getFirstUnseenArticleImg(updatedHighlightsData),
        startIndex: getIndexOfFirstUnseen(updatedHighlightsData.articles),
      },
      {
        name: 'ticketsAB',
        header: 'Вопросы AB',
        img: getFirstUnseenTicketsImg("ab", updatedHighlightsData),
        startIndex: getIndexOfFirstUnseen(updatedHighlightsData.tasksAB),
      },
      {
        name: 'ticketsCD',
        header: 'Вопросы CD',
        img: getFirstUnseenTicketsImg("cd", updatedHighlightsData),
        startIndex: getIndexOfFirstUnseen(updatedHighlightsData.tasksCD),
      },
      {
        name: 'videos',
        header: 'Видео',
        img: getFirstUnseenVideoImg(updatedHighlightsData),
        startIndex: getIndexOfFirstUnseen(updatedHighlightsData.videos),
      },
    ]);
    setCurrentViewer(null);
  };

  const getCurrentStoriesData = (data) => {
    switch (currentViewer) {
      case 'articles': return data.articles;
      case 'ticketsAB': return data.tasksAB;
      case 'ticketsCD': return data.tasksCD;
      case 'videos': return data.videos;
      default: return [];
    }
  };


  return (<>
    {loading && <MainStoriesComponentSkeleton />}
    {error && <div>Error: {error}</div>}
    {highlightsData && Object.keys(highlightsData).length > 0 && (
      <div className='main-stories-component'>
        <div className='highlights-container'>
          {highlights.map((h, index) => (
            <Highlight
              key={h.name}
              name={h.name}
              header={h.header}
              img={h.img}
              openHandler={(name, rect) => openViewer(name, rect, index)}
              isSeen={seenState[h.name]}
            />
          ))}
        </div>

        {currentViewer && (
          <StoryViewer
            onClose={closeViewer}
            header={highlights.find(h => h.name === currentViewer).header}
            highlightRect={highlightRect}
            storiesData={getCurrentStoriesData(highlightsData)}
            currentViewer={currentViewer}
            startIndex={highlights.find(h => h.name === currentViewer).startIndex}
            navigateToNextHighlight={navigateToNextHighlight}
            navigateToPreviousHighlight={navigateToPreviousHighlight}
          />
        )}
      </div>
    )}
  </>
  );
};

export default MainStoriesComponent;

