import React from 'react';
import { Helmet } from "react-helmet";

function HelmetMain(props) {
  const year = new Date().getFullYear();
  return (
    <Helmet>
      <title>
        {`ПДД РФ ${year}: Тесты, Экзамен, Теория, Дорожные Знаки и Разметка - Готовьтесь к сдаче на водительские права! PDDPORTAL.RU`}
      </title>
      <meta name="keywords" content="ПДД, ПДД РФ, Экзамен на права, автобилеты, билеты как в ГАИ, ПДД 2024, ПДД 2024, теория ПДД, видео ПДД, пдд, автошкола" />
      <meta
        name="description"
        content={`Пройдите онлайн тесты, подготовьтесь к экзамену в ГАИ. Ознакомьтесь с последними изменениями в Правилах Дорожного Движения России на ${year} год.  Освежите знания по теории, дорожным знакам и дорожной разметке. Помогаем подготовиться к сдаче экзамена на водительские удостоверение!`}
      />
      <meta
        property="og:title"
        content={`ПДД РФ ${year}: Тесты, Экзамен, Видео, Теория, Дорожные Знаки и Разметка - Готовьтесь к сдаче на водительские права! PDDPORTAL.RU`}
      />
      <meta
        property="og:description"
        content={`Пройдите онлайн тесты, подготовьтесь к экзамену в ГАИ. Ознакомьтесь с последними изменениями в Правилах Дорожного Движения России на ${year} год.  Освежите знания по теории, дорожным знакам и дорожной разметке. Помогаем подготовиться к сдаче экзамена на водительские удостоверение!`}
      />
      <meta property="og:image" content="/images/icons/logo192.png"></meta>
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://pddportal.ru/" />
      <meta property="og:site_name" content="PDDPORTAL.RU" />
      <meta property='og:locale' content='ru_RU' />
      <meta property="og:image:width" content="192" />
      <meta property="og:image:height" content="192" />
      <link rel="canonical" href={`https://pddportal.ru/`} />
      <link rel="shortcut icon" href="/images/icons/favicon.ico" />
      <link rel="apple-touch-icon" href="/images/icons/logo192.png" />
    </Helmet>
  );
}

export default HelmetMain;