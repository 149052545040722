import React, { useState } from "react";
import Confirm from "../../../shared/Confirm";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
function ResetBtns(props) {
  const [choice, setChoise] = useState(null);

  const generateConfirmText = (choice) => {
    switch (choice) {
      case "reset-progress":
        return "Вы уверены, что хотите сбросить прогресс всех билетов?";
      case "reset-errors":
        return "Вы уверены, что хотите удалить все ошибки?";
      default:
        break;
    }
  };

  const action = (confirm) => {
    if (confirm) {
      switch (choice) {
        case "reset-progress":
          localStorage.removeItem("ticketStatusArray");
          props.resetTicketStatusArray();
          break;
        case "reset-errors":
          localStorage.removeItem("errorsArray");
          break;
        default:
          break;
      }
    }
    setChoise(null);
    props.setShouldReloadStatsData(true)
  };

  return (
    <>
      {!choice ? (
        <div className="controll-buttons">
          <div
            className="button"
            onClick={() => {
              setChoise("reset-progress");
            }}
          >
            Сброс прогресса
          </div>
          <div
            className="button"
            onClick={() => {
              setChoise("reset-errors");
            }}
          >
            Сброс ошибок
          </div>
        </div>
      ) : (
        <Confirm action={action}>
          {generateConfirmText(choice)}
        </Confirm>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetTicketStatusArray: () =>
      dispatch({
        type: actionTypes.RESET_TICKET_STATUS_ARRAY,
      }),
  };
};

export default connect(null, mapDispatchToProps)(ResetBtns);
