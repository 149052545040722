import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "./Achivments.css";
import Badge from "./Badge";
import achivmentsData from "./achivmentsData";
import VkGroupClick from "../../../shared/components/VkGroupClick";
import ProgressBar from "../Stats/ProgressBar";

function Achivments(props) {
  const getAchivmentsDataFromLS = () => {
    let achivmentsLS = localStorage.getItem("achivments");
    if (achivmentsLS) {
      return JSON.parse(achivmentsLS);
    }
  };

  const achivmentsBlockRef = useRef();
  const badgeInfoRef = useRef();
  const [clickedId, setClickedId] = useState(null);
  const [positionImg, setPositionImg] = useState();
  const [offsetInfo, setOffsetInfo] = useState();
  const [centerImg, setCenterImg] = useState();
  const [finalOfset, setFinalOfset] = useState();
  const [achivmentsState, setAchivmentsState] = useState(
    getAchivmentsDataFromLS()
  );

  const onAchivmentClick = (i, xImgPosition, centerImg) => {
    if (clickedId === i) setClickedId(null);
    else setClickedId(i);
    setPositionImg(xImgPosition);
    setCenterImg(centerImg);
  };

  function getRowId(index) {
    return Math.floor(index / 3) + 2;
  }
  useEffect(() => {
    if (props.alerts.length > 0) {
      setAchivmentsState(getAchivmentsDataFromLS());
    }

    const onAchivmentsBlockClick = (event) => {
      if (
        achivmentsBlockRef.current &&
        achivmentsBlockRef.current.contains(event.target)
      ) {
        return;
      }
      setClickedId(null);
    };
    document.body.addEventListener("click", onAchivmentsBlockClick);

    if (clickedId !== null) {
      setOffsetInfo(badgeInfoRef.current.offsetLeft);
      setFinalOfset(positionImg - offsetInfo - 6 + centerImg);
    }

    return () => {
      document.body.removeEventListener("click", onAchivmentsBlockClick);
    };
  }, [clickedId, offsetInfo, positionImg, centerImg, props.alerts]);

  const printDescription = () => {
    if (achivmentsData[clickedId].name === "friend")
      return (
        <div className="badge-p">
          {achivmentsData[clickedId].description}{" "}
          <VkGroupClick>
            <a
              href="https://vk.com/pdd_portal"
              target="_blank"
              rel="noopener noreferrer"
              className="achivment-a-tag"
            >
              Ссылка на группу
            </a>
          </VkGroupClick>
        </div>
      );
    else
      return <p className="badge-p">{achivmentsData[clickedId].description}</p>;
  };

  const getProgress = (name) => {
    const progress = achivmentsState[name].currentProgress;
    const forComplete = achivmentsState[name].forComplete;
    if (progress > forComplete) return forComplete;
    else return progress;
  };

  const getForComplete = (name) => {
    return achivmentsState[name].forComplete;
  };

  const calcPercent = (name) => {
    return (getProgress(name) * 100) / getForComplete(name);
  };

  const printProgressBar = (isComplete) => {
    if (isComplete) return;
    else
      return (
        <div style={{ marginBottom: "20px" }}>
          <ProgressBar
            completedPercent={calcPercent(achivmentsData[clickedId].name)}
            name="Прогресс"
            progress={
              getProgress(achivmentsData[clickedId].name) +
              " из " +
              getForComplete(achivmentsData[clickedId].name)
            }
          />
        </div>
      );
  };

  return (
    <div ref={achivmentsBlockRef}>
      <h2 className="dash-header">Достижения</h2>
      <p className="dash-header">нажмите на значок для подробностей </p>
      <div className="achivments-gallery">
        {achivmentsData.map((item, i) => (
          <div key={i}>
            <Badge
              id={i}
              clickedId={clickedId}
              url={item.url}
              title={item.description}
              onAchivmentClick={onAchivmentClick}
              badgeState={achivmentsState[item.name]}
            />
          </div>
        ))}
        {clickedId !== null && (
          <div
            ref={badgeInfoRef}
            className="badge-info"
            style={{ gridRowStart: `${getRowId(clickedId)}` }}
          >
            <span style={{ left: `${finalOfset}px` }}></span>
            <b>
              <p className="badge-p">{achivmentsData[clickedId].header}</p>
            </b>

            {printDescription()}
            {printProgressBar(
              achivmentsState[achivmentsData[clickedId].name].isComplete
            )}

            {achivmentsState[achivmentsData[clickedId].name].date ? (
              <p className="badge-p">
                <b>Получено: </b>
                {achivmentsState[achivmentsData[clickedId].name].date}{" "}
              </p>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alertReducer,
  };
};

export default connect(mapStateToProps)(Achivments);
