//Highlight.jsx
import React from 'react';

const Highlight = ({ name, header, openHandler, isSeen, img }) => {

/*     console.log(img) */
    const handleClick = (event, name) => {
        const highlightRect = event.currentTarget.getBoundingClientRect();
        openHandler(name, highlightRect);
      };

    return (
        <button
            key={name}
            className={`highlight ${isSeen ? 'highlight-seen' : 'highlight-unseen'}`}
            onClick={(e)=>handleClick(e, name)}
        >
            <div className='highlight-background'></div>
            <p>{header}</p>
            {img && <img src={img} alt="Фон к истории" />}
        </button>
    );
}

export default Highlight;

