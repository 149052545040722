import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import ResetBtns from "./ResetBtns";

function Stats(props) {
  const [data, setData] = useState([]);
  const [shouldReloadStatsData, setShouldReloadStatsData] = useState()

  useEffect(() => {
    function getSuccessArrayQuantity(type) {
      const arr = JSON.parse(localStorage.getItem("ticketStatusArray"));
      if (arr) {
        return arr.filter(
          (ticket) =>
            ticket.ticketCategory.slice(
              ticket.ticketCategory.length - 2,
              ticket.ticketCategory.length
            ) === type && ticket.ticketStatus === true
            && (ticket.ticketCategory !== "themeAB") && (ticket.ticketCategory !== "themeCD")
        ).length;
      } else return 0;
    }

    function calculateSuccessPercent(type) {
      const successQuantity = getSuccessArrayQuantity(type);
      return (successQuantity * 100) / 40;
    }

    function calculateErrors(type) {
      let q = 0;
      let arr = JSON.parse(localStorage.getItem("errorsArray"));
      if (arr) {
        arr = arr.filter(
          (ticket) =>
            ticket.ticketCategory.slice(
              ticket.ticketCategory.length - 2,
              ticket.ticketCategory.length
            ) === type
        );
      } else return 0;

      arr.forEach(element => {
        q = q + element.ticketErrors.length
      });

      return q;
    }

    function calculateErrorsPercent(type) {
      const errorsQuantity = calculateErrors(type);
      return (errorsQuantity * 100) / 800;
    }

    setData([
      { completedPercent: calculateSuccessPercent("AB"), name: "Билеты АB", progress: `${getSuccessArrayQuantity("AB")} / 40` },
      { completedPercent: calculateErrorsPercent("AB"), name: "Ошибки АB", progress: calculateErrors("AB") },
      { completedPercent: calculateSuccessPercent("CD"), name: "Билеты CD", progress: `${getSuccessArrayQuantity("CD")} / 40` },
      { completedPercent: calculateErrorsPercent("CD"), name: "Ошибки CD", progress: calculateErrors("CD") },
    ]);

    setShouldReloadStatsData(false)
    if (shouldReloadStatsData) {
      props.setErrorsArray(JSON.parse(localStorage.getItem("errorsArray")))
    }

  }, [shouldReloadStatsData, props])

  return (
    <div>
      <h2 className="dash-header">Статистика</h2>

      {data.map((item, idx) => (
        <div key={idx} className="mx-1 my-1">
          <ProgressBar
            completedPercent={item.completedPercent}
            name={item.name}
            progress={item.progress}
          />
        </div>
      ))}

      <ResetBtns setShouldReloadStatsData={setShouldReloadStatsData} />

    </div>
  );
}

export default Stats;
