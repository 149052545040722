//StoryViewer.jsx
import React, { useState, useEffect, useCallback } from 'react';
import Story from './Story';
import ProgressBar from './ProgressBar';
import { useSwipeable } from 'react-swipeable';

const StoryViewer = ({ onClose, storiesData, currentViewer, highlightRect, startIndex, navigateToNextHighlight, navigateToPreviousHighlight, header }) => {
  const [startAnimation, setStartAnimation] = useState(false);
  const [swipeDownDistance, setSwipeDownDistance] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(startIndex ? startIndex : 0);
  const [progress, setProgress] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [backdropOpacity, setBackdropOpacity] = useState(1);
  const [animationPhase, setAnimationPhase] = useState('idle'); // 'idle', 'animatingNext', 'animatingPrev'


  /*   const navigateToNextStory = useCallback((event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (currentIndex < storiesData.length - 1) {
        setCurrentIndex(currentIndex + 1);
        setProgress(0);
        setIsPaused(false);
      } else {
        setIsPaused(true);
      }
    }, [currentIndex, storiesData.length]); */

  /*   const navigateToPreviousStory = (event) => {
  if (event) {
    event.stopPropagation();
  }
  if (currentIndex > 0) {
    setCurrentIndex(currentIndex - 1);
    setIsPaused(false);
    setProgress(0);
  } else if (currentIndex === 0) {
    setIsPaused(false);
    setProgress(0);
  }
}; */

  const navigateToPreviousStory = useCallback((event) => {
    if (event) {
      event.stopPropagation();
    }
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setProgress(0);
      setIsPaused(false);
    } else {
      setAnimationPhase('animatingPrev');
    }
  }, [currentIndex]);

  const navigateToNextStory = useCallback((event) => {
    if (event) {
      event.stopPropagation();
    }
    // Check if it's the last story in the current highlight
    if (currentIndex < storiesData.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setProgress(0);
      setIsPaused(false);
    } else {
      setAnimationPhase('animatingNext');
    }
  }, [currentIndex, storiesData.length]);

  useEffect(() => {
    // Delay the animation start slightly to ensure everything is rendered
    const timer = setTimeout(() => {
      setStartAnimation(true);
    }, 10); // A minimal delay can sometimes help

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let interval;
    // Only start or continue the interval if not paused
    if (!isPaused) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 0.5; // Adjust speed here
          if (newProgress < 100) {
            return newProgress;
          } else {
            clearInterval(interval); // Stop the interval when progress reaches 100%
            navigateToNextStory(); // Automatically navigate to the next story
            return 0; // Reset progress for the next story
          }
        });
      }, 50);
    }

    // Clean up the interval on component unmount or when pausing
    return () => clearInterval(interval);
  }, [isPaused, currentIndex, navigateToNextStory]);

  useEffect(() => {
    if (animationPhase === 'animatingNext') {
      const timer = setTimeout(() => {
        setAnimationPhase('idle'); // Reset the animation phase
        setProgress(0);
        setCurrentIndex(0); // Reset to the first story in the current highlight
        navigateToNextHighlight();
      }, 300); // Assuming the animation duration is 500ms
      return () => clearTimeout(timer);
    }
    else if (animationPhase === 'animatingPrev') {
      const timer = setTimeout(() => {
        setAnimationPhase('idle'); // Reset the animation phase
        setProgress(0);
        setCurrentIndex(0); // Reset to the first story in the current highlight
        navigateToPreviousHighlight();
      }, 300); // Assuming the animation duration is 500ms
      return () => clearTimeout(timer);
    }
  }, [animationPhase, navigateToNextHighlight, navigateToPreviousHighlight]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationPhase('idle');
    }, 300);
    setSwipeDownDistance(0);
    const viewerPopup = document.querySelector(".backdrop");
    if (viewerPopup) {
      viewerPopup.style.transition = '';
      viewerPopup.style.transform = '';
    }

    // Cleanup function to reset styles when the component unmounts
    return () => {
      if (viewerPopup) {
        viewerPopup.style.transition = '';
        viewerPopup.style.transform = '';
      }
      clearTimeout(timer);
    };
  }, [currentIndex]);

  useEffect(() => {
    const viewerPopup = document.querySelector(".backdrop");
    setBackdropOpacity(0);
    if (viewerPopup && highlightRect) {
      // Initial transform to match the highlight's position and size
      const scaleX = highlightRect.width / window.innerWidth;
      const scaleY = highlightRect.height / window.innerHeight;
      const translateX = highlightRect.left + (highlightRect.width / 2) - (window.innerWidth / 2);
      const translateY = highlightRect.top + (highlightRect.height / 2) - (window.innerHeight / 2);

      viewerPopup.style.transform = `translate(${translateX}px, ${translateY}px) scale(${scaleX}, ${scaleY})`;

      // Animate to full screen
      requestAnimationFrame(() => {
        viewerPopup.style.transition = 'transform 0.3s ease-in-out';
        viewerPopup.style.transform = '';
      });
    }
  }, [highlightRect]);




  const togglePause = useCallback((event) => {
    if (event) {
      event && event.stopPropagation();
    }
    setIsPaused(!isPaused);
  }, [isPaused]);


  const handleCloseWithAnimation = () => {
    setBackdropOpacity(0); // Hide backdrop with animation
    const viewerPopup = document.querySelector(".backdrop");
    if (viewerPopup && highlightRect) {

      const scaleX = highlightRect.width / window.innerWidth;
      const scaleY = highlightRect.height / window.innerHeight;
      const translateX = highlightRect.left + (highlightRect.width / 2) - (window.innerWidth / 2);
      const translateY = highlightRect.top + (highlightRect.height / 2) - (window.innerHeight / 2);

      viewerPopup.style.transition = 'transform 0.3s ease-in-out';
      viewerPopup.style.transform = `translate(${translateX}px, ${translateY}px) scale(${scaleX}, ${scaleY})`;
    }
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const handlers = useSwipeable({
    onSwipedDown: ({ event, deltaY }) => {
      event.stopPropagation();
      if (deltaY > 50) {
        handleCloseWithAnimation();
      }
      else {
        setBackdropOpacity(1); // Reset opacity if not closing
      }
    },
    onSwiping: ({ event, deltaY }) => {
      event.stopPropagation();
      if (deltaY > 0) {
        setSwipeDownDistance(deltaY);
        // Dynamically update the backdrop opacity based on swiping distance
        setBackdropOpacity(Math.max(1 - deltaY / 1000, 0));
      }
    },
    onSwiped: (event) => {
      if (swipeDownDistance <= 50) {
        setBackdropOpacity(1);
        setSwipeDownDistance(0);
      }
    },
    onTap: (swipeEvent) => {
      const event = swipeEvent.event;
      event.stopPropagation();
      let tapPointX;
      if (event.changedTouches) {
        tapPointX = event.changedTouches[0].clientX;
      } else {
        tapPointX = event.clientX;
      }

      const screenWidth = window.innerWidth;

      if (currentViewer !== 'ticketsAB' && currentViewer !== 'ticketsCD') {
        if (tapPointX < screenWidth / 2 - screenWidth * 0.1) {
          navigateToPreviousStory(event);
        } else if (tapPointX > screenWidth / 2 + screenWidth * 0.1) {
          navigateToNextStory(event);
        } else {
          togglePause(event);
        }
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: isTouchDevice,
    trackMouse: !isTouchDevice,
  });

  const renderPausePlayIcon = () => isPaused ? <i className='fa-solid fa-play'></i> : <i className="fa-solid fa-pause"></i>;

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleAnimation = () => {
    if (animationPhase === 'animatingNext') {
      return 'story-viewer-animate-next';
    }
    else if (animationPhase === 'animatingPrev') {
      return 'story-viewer-animate-prev';
    }
    return '';
  }

  const getTimestampDifference = (timestamp) => {
    const currentTime = Date.now();
    const difference = currentTime - timestamp;
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (hours > 0) {
      return hours + ' ч';
    }
    if (minutes > 0) {
      return minutes + ' мин';
    }
    return seconds + ' сек';
  }

  return (
    <div style={{ opacity: startAnimation ? 1 : 0 }}>
      <div className="additional-backdrop" style={{ opacity: backdropOpacity, backgroundColor: 'black', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 101 }}></div>
      <div className="backdrop" onClick={onClose}
        style={{
          transform: `
      translateY(${swipeDownDistance}px)
      scaleX(${1 - Math.min(swipeDownDistance / 1000, 0.2)})`,

          transition: swipeDownDistance ? 'none' : 'transform 0.5s ease-in-out'
        }}
      >
        <h2 className='backdrop-story-header'>Pddportal.ru</h2>
        <div className="story-viewer-popup" onClick={stopPropagation}

        >
          <div className="story-container">
            <div className={'story-viewer ' + handleAnimation()}>
              <ProgressBar currentIndex={currentIndex} totalCount={storiesData.length} progress={progress} />
              <div {...handlers} className="story-content">
                <Story story={storiesData[currentIndex]} isPaused={isPaused} currentViewer={currentViewer} />
              </div>

              <p className='story-viewer-header'>
                {header + "  "}
                {storiesData[currentIndex].timestamp &&
                  getTimestampDifference(storiesData[currentIndex].timestamp)}
              </p>


              <button className="story-viewer-close-button" onClick={onClose} >
                <i style={{ fontSize: "18px" }} className="fa-solid fa-times"></i>
              </button>

              <button className="story-viewer-nav-button left" onClick={(e) => navigateToPreviousStory(e)} disabled={animationPhase !== 'idle'} >
                <i className="fa-solid fa-chevron-left"></i></button>

              <button className="story-viewer-nav-button right" onClick={(e) => navigateToNextStory(e)} disabled={animationPhase !== 'idle'} ><i className="fa-solid fa-chevron-right"></i></button>

              <button style={{ fontSize: "18px" }} className="story-viewer-pause-play-button" onClick={(e) => togglePause(e)}>
                {renderPausePlayIcon()}
              </button>
            </div>

            <button className="story-viewer-nav-button left" disabled={animationPhase !== 'idle'} onClick={(e) => navigateToPreviousStory(e)}>
              <i className="fa-solid fa-chevron-left"></i></button>
            <button className="story-viewer-nav-button right" disabled={animationPhase !== 'idle'} onClick={(e) => navigateToNextStory(e)}><i className="fa-solid fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
  );

};

export default StoryViewer;

