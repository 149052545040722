// ProgressBar.jsx
import React from 'react';

const ProgressBar = ({ currentIndex, totalCount, progress }) => {
    return (
        <div className="progress-bar-container">
            {Array.from({ length: totalCount }).map((_, index) => (
                <div key={index} style={{ width: "100%", position: "relative" }}>
                    <div
                        
                        className={`progress-bar `}
                    />
                    <div
                        className={` ${index === currentIndex ? 'progress-bar active' : ''}`}
                        style={{ position: "absolute", top: "0", left: "0", width: `${index === currentIndex ? progress : 100}%` }} >
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProgressBar;
