import React, { useState, useEffect } from "react";

import "./SideBar.css";
import "../../App.css";
import Achivments from "./Achivments/Achivments";

/* import Stats from "./Stats/Stats"; */
/* import VkGroupClick from "../../shared/components/VkGroupClick";
import MobileButtons from "./MobileButtons/MobileButtons"; */

const SideBar = () => {
  const achivmentsLS = localStorage.getItem("achivments");
  const [errorsArray, setErrorsArray] = useState(
    JSON.parse(localStorage.getItem("errorsArray"))
  );

  useEffect(() => {
    if (errorsArray) {
      const check = errorsArray.filter((el) => el.ticketErrors.length !== 0);
      if (check.length === 0) {
        localStorage.removeItem("errorsArray");
        setErrorsArray(null);
      }
    }
  }, [errorsArray]);

  return (
    <div>
      <div className="side-bar-side-block">
        <div id="yandex_rtb_R-A-1691059-1"></div>
        <script>{window.yaContextCb.push(() => {
          window.Ya.Context.AdvManager.render({
            renderTo: 'yandex_rtb_R-A-1691059-1',
            blockId: 'R-A-1691059-1'
          })
        })}</script>
      </div>

     {/*  <div className="dash shared-card">
        <Stats setErrorsArray={setErrorsArray} />
      </div> */}

      {achivmentsLS && (
        <div className="dash shared-card">
          <Achivments />
        </div>
      )}

      <div className="side-bar-side-block">
        <div id="yandex_rtb_R-A-1691059-50"></div>
        <script>{window.yaContextCb.push(() => {
          window.Ya.Context.AdvManager.render({
            renderTo: 'yandex_rtb_R-A-1691059-50',
            blockId: 'R-A-1691059-50'
          })
        })}</script>
      </div>
    </div>
  );
};

export default SideBar;


/* <div className="shared-card pb-4">
<div className="side-bar-side-block">
  <div id="yandex_rtb_R-A-1691059-1"></div>
  <script>{window.yaContextCb.push(() => {
    window.Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-1691059-1',
      blockId: 'R-A-1691059-1'
    })
  })}</script>
</div>

<VkGroupClick>
  <a
    href="https://vk.com/pdd_portal"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div
      className="side-bar-side-block vk-banner"
      style={{ backgroundImage: `url(${"/vk-group.jpg"})` }}
    >
      <h3 className="vk-banner-header">Наша группа в ВК</h3>
      <div className="bottom-shadow"></div>
    </div>
  </a>
</VkGroupClick>

<MobileButtons /> 

</div> */