import React from "react";
import ContextMenu from "../../shared/Navigation/ContextMenu";
import HelmetMain from "../components/HelmetMain";
import loadable from "@loadable/component";

import "../../App.css";
import DashBoard from "../components/DashBoard";
import SideBar from "../components/SideBar";
import MainStoriesComponent from "../../stories/MainStoriesComponent";


const VideoSectionRandom = loadable(() => import("../../video-section/VideoSectionRandom"), {
  delay: 100,
  fallback: <div>Loading...</div>,
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}


const Main = ({ setAlert }) => {
  return (
    <>
      <HelmetMain />
      <div className="grid-container">
         <ContextMenu notRenderBurgerMenuIcon={true}>{() => { }}</ContextMenu>


        <div className="dash-board">
        <ErrorBoundary>
          <MainStoriesComponent />
        </ErrorBoundary>
          <DashBoard />
        </div>

        <div className="side-bar">
          <SideBar />
        </div>

        <div className="video-section-main-page">

          <VideoSectionRandom limitForShow={16} />

          {/* Рекомендательный виджет */}
          {/* <div id="yandex_rtb_C-A-1691059-40"></div>
          <script>
            {window.yaContextCb.push(() => {
              window.Ya.Context.AdvManager.renderWidget({
                renderTo: 'yandex_rtb_C-A-1691059-40',
                blockId: 'C-A-1691059-40'
              })
            })}</script> */}
          {/* Рекомендательный виджет конец */}

        </div>
      </div>
    </>
  );
};

export default Main;





/*  <div className="only-mobile">
 <div id="yandex_rtb_R-A-1691059-8"></div>
 <script>{window.yaContextCb.push(() => {
   window.Ya.Context.AdvManager.renderFeed({
     renderTo: 'yandex_rtb_R-A-1691059-8',
     blockId: 'R-A-1691059-8'
   })
 })}</script>
</div> */