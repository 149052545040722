//ArticleH.jsx
import React from 'react';
import './ArticleH.css';
import ActionStoryButton from './ActionStoryButton';
import { setCurrentArticle } from '../../store/actions/articleActions';
import { useDispatch } from 'react-redux';
import ImageWrapper from '../../shared/components/ImageWrapper';

const ArticleH = React.memo(({ articleItem }) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        window.scrollTo(0, 0);
        const article = {
            title: articleItem.title,
            description: articleItem.description,
            image: articleItem.image,
            urlName: articleItem.urlName,
            markdown: articleItem.markdown,
            author: articleItem.author,
            date: articleItem.date,
            hideFromOffset: 0,
        }
        dispatch(setCurrentArticle(article));
    }

    const generateLink = (articleItem) => {
        const articleLink = articleItem.urlName;
        if (articleLink) {
            return '/articles/other/' + articleLink + '/';
        }
        return '';
    }
    return (
        <>
            {articleItem &&
                <div>
                    <div className='articleh-wrapper'>
                        {articleItem.image &&
                            <ImageWrapper
                                src={articleItem.image}
                                alt={articleItem.title}
                                draggable="false"
                            />
                        }
                        <div className='articleh-description'>
                            <h2 className='articleh-header'>{articleItem.title}</h2>
                            <p>{articleItem.description}</p>
                        </div>
                    </div>
                    <div onClick={handleClick}>
                        <ActionStoryButton link={generateLink(articleItem)}>
                            Читать полностью
                        </ActionStoryButton>
                    </div>

                </div>
            }
        </>
    );
});

export default ArticleH;

/* import React from 'react';
import './ArticleH.css';
import ActionStoryButton from './ActionStoryButton';

function ArticleH({ articleItem }) {
    // Check if the articleItem and its nested properties exist before rendering
    if (!articleItem || !articleItem.article || !articleItem.article.image || !articleItem.article.title || !articleItem.article.description) {
        // Optionally, render a placeholder or a loading state instead
        return <div>Loading article...</div>;
    }

    return (
        <div>
            <div className='articleh-wrapper'>
                <img src={articleItem.article.image} alt={articleItem.article.title} />
                <div className='articleh-description'>
                    <h2 className='articleh-header'>{articleItem.article.title}</h2>
                    <p>{articleItem.article.description}</p>
                </div>
            </div>
            <ActionStoryButton>
                Читать полностью
            </ActionStoryButton>
        </div>
    );
}

export default ArticleH; */
