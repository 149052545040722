import React from 'react';
import VideoPreviewMaxi from './videoPreview';
import ActionStoryButton from './ActionStoryButton';
import { setCurrentVideo } from '../../store/actions/videoActions';
import { useDispatch } from 'react-redux';


function YoutubeH({ videoData }) {

    const dispatch = useDispatch();

    const handleClick = () => {
        const video = {
            videoId: videoData.video.id.videoId,
            thumbnailUrl: videoData.video.snippet.thumbnails.high.url,
            title: videoData.video.snippet.title,
            channelName: videoData.video.snippet.channelTitle,
            publishedAt: videoData.video.snippet.publishedAt,
            description: videoData.video.snippet.description
        }
        dispatch(setCurrentVideo(video));
    }

    const generateLink = (videoData) => {
        const section = videoData.name;
        if (section) {
            return `/category/${section}/${videoData.video.id.videoId}`
        }
        return '';
    }

    return (
        <div>
            <VideoPreviewMaxi
                thumbnailUrl={videoData.video.snippet.thumbnails.high.url}
                title={videoData.video.snippet.title}
                channelName={videoData.video.snippet.channelTitle}
                publishedAt={videoData.video.snippet.publishedAt}
                videoId={videoData.video.id.videoId}
            />
            <div onClick={() => dispatch(handleClick)}>
                <ActionStoryButton link={generateLink(videoData)}>
                    Смотреть видео
                </ActionStoryButton>
            </div>
        </div>
    );
}

export default YoutubeH;