// Story.jsx
import React, { useEffect } from 'react';
import TicketH from './storyComponents/TicketH';
import YoutubeH from './storyComponents/YoutubeH';
import ArticleH from './storyComponents/ArticleH';

const Story = React.memo(({ story, currentViewer, togglePause }) => {

  useEffect(() => {
    const highlightsData = JSON.parse(localStorage.getItem("highlightsData"));

    Object.keys(highlightsData).forEach(category => {
      const storyIndex = highlightsData[category].findIndex(s => s.storyId === story.storyId);
      if (storyIndex !== -1) {
        highlightsData[category][storyIndex].seen = true; // Mark the story as seen
      }
    });

    localStorage.setItem("highlightsData", JSON.stringify(highlightsData));
  }, [story]);

  const renderStoryContent = (story) => {
    if (currentViewer === 'ticketsAB' || currentViewer === 'ticketsCD') {
      return <TicketH ticket={story} togglePause={togglePause} />
    }
    else if (currentViewer === 'videos') {
      return <YoutubeH videoData={story} />
    }
    else if (currentViewer === 'articles') {
      return <ArticleH articleItem={story} />
    }
    return story.content;
  }

  return (
    <div className="story">
      {renderStoryContent(story)}
    </div>
  );
});

export default Story;
