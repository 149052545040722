//ImageWrapper.jsx
import React, { useState } from 'react';

const skeletonStyle = {
  backgroundColor: '#eee',
  height: '100px', // Adjust these values as needed
  width: '100%',
};

const ImageWrapper = ({ src, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && (
        // Show skeleton loader if not loaded
        <div className='skeleton' style={skeletonStyle}></div>
      )}
      <img
        key={src}
        src={src}
        alt={alt}
        style={{ display: loaded ? 'block' : 'none', ...props.style }}
        onLoad={() => setLoaded(true)}
        onError={() => setLoaded(false)} // Optionally handle error state
        {...props} // Spread any additional props passed to the component
      />
    </>
  );
};

export default ImageWrapper;
