import React, { useState } from 'react';

import './video.css';

const VideoPreviewMaxi = (props) => {
    const [isImgExist, setIsImgExist] = useState(true);
    const [loaded, setLoaded] = useState(false);

    const { thumbnailUrl, title, channelName, publishedAt } = props;

    const formatTitle = (title) => {
        const maxLength = 75 // Maximum characters for the title
        if (title.length > maxLength) {
            return `${title.slice(0, maxLength)}...`; // Truncate if too long
        }
        return title;
    }
    
    const formatPublishedAt = (publishedAt) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(publishedAt).toLocaleDateString(undefined, options);
    }


    const onLoad = (e) => {
        const naturalWidth = e.target.naturalWidth;
        const naturalHeight = e.target.naturalHeight;
        if (naturalWidth === 120 && naturalHeight === 90) {
            setIsImgExist(false);
        }
        setLoaded(true);
    }

    return (
        <>
            {isImgExist && <div className="video-preview-maxi">
                <div>
                    <div className="thumbnail">
                        {!loaded && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    backgroundColor: 'lightgrey', // Grey placeholder color
                                }}
                            > </div>

                        )}
                        <img
                            src={thumbnailUrl}
                            alt="Превью картинка для видео"
                            onLoad={(e) => onLoad(e)}
                            loading="lazy"
                            draggable="false"
                        />
                    </div>
                    <div className="info" style={{color:"white"}}>
                        <h2 className="title">{formatTitle(title)}</h2>
                        <p className="channel" style={{color:"white"}}>{channelName}</p>
                        <p className="publishedAt" style={{color:"white"}}>{formatPublishedAt(publishedAt)}</p>
                    </div>
                </div>
            </div>}
            {!isImgExist && props.videosArrLength < 40 && <div className='video-preview-maxi-skeleton'>
            </div>}
        </>
    );
}


export default VideoPreviewMaxi;