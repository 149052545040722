import React, { useRef } from "react";

function Badge(props) {
  const imgRef = useRef();

  return (
    <div>
      <img
        ref={imgRef}
        className={
          "achivment-img " +
          (props.badgeState.isComplete === false ? "blocked" : "")
        }
        src={props.url}
        alt="достижение"
        title={props.title}
        onClick={() => {
          props.onAchivmentClick(
            props.id,
            imgRef.current.offsetLeft,
            imgRef.current.offsetWidth / 2
          );
        }}
      ></img>
    </div>
  );
}

export default Badge;
