import React from "react";

const ProgressBar = (props) => {
  const { completedPercent, name, progress,
    containerStyles: customContainer,
    fillerColor,
    labelStyles: customLabel,
    nameStyle: customName
  } = props;



  const containerStyles = customContainer || {
    height: 20,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: "0 auto",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completedPercent}%`,
    backgroundColor: fillerColor || "#0267bf",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = customLabel || {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  const nameStyle = customName || {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "7px",
    marginLeft: "7px",
  };

  return (
    <>
      <div style={nameStyle}>
        {name && <p>{name}</p>}
        {progress !== undefined && <p>{progress}</p>}
      </div>
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <div style={labelStyles}></div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;

