import React, { useCallback } from "react";
import "./Answer.css";
import ProgressBar from "../../main/components/Stats/ProgressBar";

const Answer = (props) => {

  const handler = useCallback(() => {
    if (props.id !== props.rightAnswer) {
      props.wrongAnswerHandler(props.id, true);
    } else props.wrongAnswerHandler(props.id, false);
  }, [props]);

  const onClickedId = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handler();
  };

  const classHandler = () => {
    if (props.answeredIndex !== false) {
      if (props.id === props.rightAnswer) return "green ";
      else return "red ";
    }
    else {
      if (
        (props.isError && props.clickedAnswerId !== null) === true &&
        props.id === props.rightAnswer
      )
        return "green ";
      if (
        (props.clickedAnswerId === props.rightAnswer &&
          (props.clickedAnswerId) === props.id)
      )
        return "green ";
      if ((props.clickedAnswerId) === props.id) return "red ";
      else return "";
    };
  }

  const nonePointer = () => {
    if (props.clickedAnswerId !== null || props.answeredIndex !== false) {
      return "non-pointer";
    }
  }

  const calculatePercentage = (answerCount, total) => {
    /* console.log(answerCount, total) */
    if (!answerCount || !total) return 0
    if (total === 0) return 0
    const percentage = (answerCount / total) * 100;
    return `${percentage.toFixed(0)}`; // Format to 2 decimal places
  };

  const containerStyles = {
    height: " 100%",
    width: "100%",
    backgroundColor: "white",
    margin: "0 auto",
    position:"absolute",
    top: "0",
    left: "0",
    zIndex: "1",
    opacity: "0.09"
  }

  const fillerColor = "black"
  const labelStyles =  {};
  const nameStyle =  {};

  return (
    <div
      onClick={(e) => onClickedId(e)}
      className={
        "answer " +
        (classHandler() === undefined ? "" : classHandler()) + nonePointer()
      }
    >
      <div className="answer-wrapper">
        <p className="select-answer">
          {props.answeredIndex === props.id && <b> Ваш ответ - </b>}
          <b>{props.answer.slice(0, 2)}</b>
          {props.answer.slice(2, props.answer.length)}
        </p>
        <div className="answer-stat-wrapper">

          {props.taskStat && <div className="answer-stat">{props.taskStat.stats[props.id] ? (`(${props.taskStat.stats[props.id]})`) : ""}</div>}

          {props.taskStat && <div className="answer-stat">
            {calculatePercentage(props.taskStat.stats[props.id], props.summary)}%
          </div>}
        </div>

      </div>

      {props.answeredIndex !== false && props.taskStat && (
        <ProgressBar
          containerStyles={containerStyles}
          fillerColor={fillerColor}
          labelStyles={labelStyles}
          nameStyle={nameStyle}
          completedPercent={calculatePercentage(props.taskStat.stats[props.id], props.summary)}
        />
      )}
    </div>

  );
};

export default Answer